import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { ProductTypes } from '@types/content/product';

import Layout from 'components/Layout';
import Banner from 'components/common/Banner';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';
import CategoriesNav from 'components/common/CategoriesNav';
import ProductList from 'components/ProductList';
import ProductListFilter from 'components/ProductListFilter';
import NewsletterButtonBanner from 'components/common/NewsletterButtonBanner';
import Overlay from 'components/common/Overlay';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { getLocationQueryStringParam } from 'utils/browser';
import { addMissingHeroImage } from 'utils/products';
import { gtmService } from 'services/gtmService';

import 'styles/main.scss';
import './product-listing.scss';

const PRODUCTS_SHOW_MORE_COUNT = 8;
const defShowFilters = 'Show filters (%num%)';
const defHideFilters = 'Hide filters (%num%)';

const getShownResultsCount = (resultsCount: number): number =>
  resultsCount >= PRODUCTS_SHOW_MORE_COUNT ? PRODUCTS_SHOW_MORE_COUNT : resultsCount;

type TProps = {
  pageContext: {
    link: string;
    xOrigin: string;
  };
  data: IProductListingPageData;
};

interface IProductListingPageData extends PageContent.IDefaultLayoutProps {
  page: ListingPage.TListingPage;
  allProducts: Record<'nodes', ProductTypes.IProduct[]>;
}

const ProductListing: FC<TProps> = ({
  pageContext: { xOrigin },
  data: {
    page,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
    allProducts,
  },
}) => {
  const { productsMatrix, link, name } = page;
  const options = [{ name: siteSettings?.sortAZ }, { name: siteSettings?.sortZA }];
  addMissingHeroImage(productsMatrix, allProducts?.nodes);

  const sortAsc = (a, b) => a.name?.localeCompare(b.name);
  const sortDesc = (a, b) => b.name?.localeCompare(a.name);

  const sortingsList = {
    [options[0].name]: sortAsc,
    [options[1].name]: sortDesc,
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductTypes.IProduct[]>(
    productsMatrix?.length ? productsMatrix.sort(sortAsc) : []
  );
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [shownResultsCount, setShownResultsCount] = useState<number>(
    getShownResultsCount(productsMatrix?.length || 0)
  );
  const startCursor = useRef(0);
  const endCursor = useRef(0);

  const isShowLoadMoreButton = shownResultsCount < products.length;
  const clearFilters = useCallback(() => {
    setProducts(productsMatrix);
    setShownResultsCount(getShownResultsCount(productsMatrix?.length || 0));
    setFiltersCount(0);
  }, []);

  const fetchProduct = async (currentFilters = []) => {
    setLoading(true);

    const tipsTags = [...currentFilters].join(',');
    const masterTags = [...page.masterTag.map(({ id }) => id)].join(',');
    const response = await fetch(
      `/api/filter-products?masterTags=${masterTags}&salsifyLang=${siteSettings?.salsifyLang}&tipsTags=${tipsTags}`,
      {
        method: 'GET',
        headers: { 'x-origin': xOrigin },
      }
    );

    if (response.ok) {
      const productsList = await response.json();

      const skuList = productsList?.map((value) => value?.sku) || [];
      const filteredProductsMatrix = productsMatrix.filter(function (productMatrix) {
        return skuList.includes(productMatrix.sku);
      });

      setFiltersCount(getLocationQueryStringParam('type').length);
      setProducts(filteredProductsMatrix.sort(sortAsc));
      setShownResultsCount(getShownResultsCount(productsList.length));
      startCursor.current = 0;
    } else {
      setShownResultsCount(0);
      setProducts([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentFilters = getLocationQueryStringParam('type');
    if (currentFilters && currentFilters.length > 0) {
      fetchProduct(getLocationQueryStringParam('type'));
    }
  }, [page?.masterTag]);

  const handleLoadMore = () => {
    setShownResultsCount((prevState) => {
      return prevState + PRODUCTS_SHOW_MORE_COUNT >= products.length
        ? products.length
        : prevState + PRODUCTS_SHOW_MORE_COUNT;
    });
  };

  const handleProductsSort = useCallback(
    (sortingType) => {
      setProducts([...products.sort(sortingsList[sortingType])]);
    },
    [products]
  );

  const getFilterBtnText = useCallback(() => {
    let filterBtnText = '';
    if (showFilters) {
      filterBtnText =
        filtersCount > 0
          ? (siteSettings.hideFilters ?? defHideFilters).replace('%num%', `${filtersCount}`)
          : page.productListFilterOpenedText;
    } else {
      filterBtnText =
        filtersCount > 0
          ? (siteSettings.showFilters ?? defShowFilters).replace('%num%', `${filtersCount}`)
          : page.productListFilterClosedText;
    }

    return filterBtnText;
  }, [showFilters, filtersCount]);

  useEffect(() => {
    const total = products?.length;

    if (!total || !shownResultsCount) return;

    endCursor.current = shownResultsCount <= total ? shownResultsCount : endCursor.current;

    if (startCursor.current === endCursor.current) return;

    const itemsToSend = products.slice(startCursor.current, endCursor.current);
    startCursor.current = endCursor.current;

    const timerId = gtmService.emitProductListingView(name, itemsToSend);

    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [products, shownResultsCount]);

  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
  } = page;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      className="product-page"
      languageSelector={languageSelector}
      url={link}
    >
      <Banner
        swapTitleFont={page.swapTitleTextFont}
        title={{
          regularText: page.titleRegular,
          boldText: page.titleBold,
        }}
        subTitle={page.name}
        img={page.image}
        alt={page.altImage}
        text={page.subTitle}
      />

      <Container className="dt-product-listing" fluid>
        <CategoriesNav
          selectedTag={page.masterTag?.[0]}
          categories={page.subCategories}
          btnDropdown={siteSettings.chooseCategoryText ?? 'Choose a category'}
        />
        <strong className="dt-product-listing__sub-title">{page.description}</strong>
        {page.filterTypes ? <hr className="dt-hr-wide d-none d-lg-block" /> : null}
        {!loading && products.length ? (
          <>
            <ProductListFilter
              setShowFilters={setShowFilters}
              shownCount={shownResultsCount}
              totalCount={products.length}
              filtersCount={filtersCount}
              hideSortButton={siteSettings?.hideSortButton}
              options={options}
              fetchProduct={fetchProduct}
              clearFilters={clearFilters}
              onSelect={handleProductsSort}
              showFilters={showFilters}
              filterBtnText={getFilterBtnText()}
              filterTypes={page.filterTypes}
              applyFiltersText={page.applyFiltersText}
              showingOf={siteSettings?.showingOf}
            />
            <ProductList
              products={products.slice(0, shownResultsCount)}
              handleLoadMore={handleLoadMore}
              isHovered
              showRating={!!siteSettings?.useBV}
              btn={
                isShowLoadMoreButton
                  ? {
                      btnText: page.loadMoreText,
                    }
                  : null
              }
              useFixedProductImage={siteSettings.useFixedProductGatsbyImage}
            />
          </>
        ) : (
          <div className="dt-no-result-text">{loading ? '' : page.noResultsText}</div>
        )}
      </Container>

      {page.descriptionBottom ? (
        <div className="container-fluid">
          <div className="justify-content-md-center row">
            <div className="col-lg-8 col-12">
              <DangerouslySetInnerHtml
                html={page.descriptionBottom}
                className="dt-product-listing__description-bottom"
              />
            </div>
          </div>
        </div>
      ) : null}

      {Number(siteSettings?.newsletterSignUp) ? (
        <NewsletterButtonBanner newsletterBanner={homepageSettings?.newsletterBanner} />
      ) : null}

      <DidYouKnowLoadableWrapper didYouKnow={page.didYouKnow} mask />
      <Overlay visible={loading} />
    </Layout>
  );
};

export const query = graphql`
  query ProductListingPageQuery($link: String!, $lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      useBV
      sortAZ
      sortZA
      showFilters
      hideFilters
      showingOf
      chooseCategoryText
      salsifyLang
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
      newsletterBanner {
        buttonText
        buttonURL {
          url
        }
        titleText
        regularTitle
      }
    }
    page: umbracoProductListingPage(link: { eq: $link }, lang: { eq: $lang }) {
      productsMatrix {
        ean
        descriptionLong
        descriptionShort
        localHeroImage {
          childImageSharp {
            fluid(maxHeight: 300, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          fixedMobileRelatedImage: childImageSharp {
            fixed(height: 160, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
          fixedDesktopRelatedImage: childImageSharp {
            fixed(height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        heroImage
        id
        link
        name
        recognisableFeature
        size
        scent
        sku
        titleBrandbank
      }
      applyFiltersText
      loadMoreText
      noResultsText
      productListFilterOpenedText
      productListFilterClosedText
      link
      description
      descriptionBottom
      name
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
      swapTitleTextFont
      titleBold
      titleRegular
      masterTag {
        id
      }
      filterTypes {
        image {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        name
        title
        subTitle
        imageAlt
        id
      }
      subCategories {
        id
        name
        title
        link {
          url
        }
      }
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
      image {
        fluid {
          srcSet
          base64
        }
        fallbackUrl
      }
    }
    allProducts: allUmbracoProduct(filter: { lang: { eq: $lang } }) {
      nodes {
        sku
        localHeroImage {
          childImageSharp {
            fluid(maxHeight: 300, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          fixedMobileRelatedImage: childImageSharp {
            fixed(height: 160, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
          fixedDesktopRelatedImage: childImageSharp {
            fixed(height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default ProductListing;
