import React, { FC } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import { ICategoriesNavItemProps } from 'gatsby-theme-dettol/src/components/common/CategoriesNav/CategoriesNavItem/models';

const CategoriesNavItem: FC<ICategoriesNavItemProps> = ({ selected, tag: { name, link, title } }) =>
  link ? (
    <li>
      <Link to={link[1] && selected ? link[1].url : extractUrlFromMultiPicker(link)} className={cx({ selected })}>
        {title || name}
      </Link>
    </li>
  ) : null;

export default CategoriesNavItem;
