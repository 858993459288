import { ProductTypes } from '@types/content/product';

export const addMissingHeroImage = (
  target: ProductTypes.IProduct[],
  source: ProductTypes.IProduct[]
) => {
  if (!Array.isArray(target) && !Array.isArray(source)) return;

  target.forEach((product) => {
    if (!product.localHeroImage) {
      const { localHeroImage } = source.find(({ sku }) => sku === product.sku) || {};
      if (localHeroImage) product.localHeroImage = localHeroImage;
    }
  });
};

export default addMissingHeroImage;
